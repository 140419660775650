// nav-list
mainMenu('.nav-list','.nav-toggle'); 
function mainMenu(menu,btn){ 
	var menu = $(menu), 
	btn = $(btn); 
	btn.on('click',function(){ 
		btn.blur(); 
		if(btn.hasClass('disable')){ 
			btn.removeClass('disable') 
			btn.attr("data-disable","false") 
			menu.removeClass('disable'); 
			menu.slideDown(300); 
		}else{ 
			btn.addClass('disable') 
			btn.attr("data-disable","true") 
			menu.addClass('disable'); 
			menu.slideUp(300); 
		} 
	}) 
	$(btn).attr("data-disable","true"); 
	toggleMenu(btn,menu); 
	$(window).resize(function(){ 
		toggleMenu(btn,menu); 
	}) 
	function toggleMenu(btn,menu){ 
		if($(btn).is(":visible") && $(btn).attr("data-disable")=="true"){ 
			$(btn).addClass('disable'); 
			$(menu).addClass('disable'); 
			$(menu).hide(); 
		}else if(!$(btn).is(":visible")){ 
			$(btn).removeClass('disable'); 
			$(menu).removeClass('disable'); 
			$(menu).show(); 
		} 
	} 
}

// nav-toggle
$('.nav-toggle').on('click', function(){
	$(this).toggleClass('nav-toggle--open');
})

function drop(){
	if ($(window).width() < 767) {
		$('.nav-list__link').parent().each(function(){
			if ($(this).has('.nav-list-hidden').length){
				$(this).find('.nav-list__link').addClass('nav-list__link--drop');
			}
		})
	}else if($(window).width() > 767){
		$('.nav-list__link').removeClass('nav-list__link--drop');
		$('.nav-list__link').removeClass('nav-list__link--active');
		
		$('.nav-list__link').each(function(){
			if(!$(this).hasClass('nav-list__link--active')){
				$(this).parent().find('.nav-list-hidden').removeClass('nav-list-hidden--show');
			}
		})
	}
}
drop()

$(window).resize(function(){
	drop()
	linkDrop()
})

function linkDrop(){
	$('.nav-list__link--drop').on('click', function(e){
		e.preventDefault();
		$(this).toggleClass('nav-list__link--active');
		$(this).parent().find('.nav-list-hidden').toggleClass('nav-list-hidden--show');
	})
}
linkDrop()